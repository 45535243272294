export const profileFormFields = (values, setFieldValue) => {
  return [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Juan",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
      inputMode: "text",
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Dela Cruz",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
      inputMode: "text",
    },
    {
      type: "text",
      name: "mobileNumber",
      label: "Mobile Number",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
      disabled: true,
      isContactNumber: true,
    },
    {
      type: "text",
      name: "email",
      label: "Email",
      placeholder: "jdelacruz@gmail.com",
      inclusions: ["edit"],
      // disabled: true,
      isFollowUpQuestion: false,
      isRequired: true,
      onKeyPress: (event) => {
        if (event.key === " ") event.preventDefault()
      },
    },
    // {
    //   type: "date",
    //   name: "birthday",
    //   label: "Birthday",
    //   inclusions: ["edit"],
    //   values: values?.birthday,
    //   isRequired: true,
    //   dateConfig: `{ "birthday": true }`,
    // },
    {
      type: "address",
      fieldNames: {
        addressType: `address.addressType`,
        streetAddress: `address.streetAddress`,
        city: `address.city`,
        province: `address.province`,
        barangay: `address.barangay`,
      },
      values: values,
      setFieldValue: setFieldValue,
      inclusions: ["add-address", "edit-address"],
      isNationwide: false,
      isRequired: true,
    },
  ]
}
